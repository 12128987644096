import authService from "./authService";

export const AuthService = {
  storage: authService.storage,
  functions: authService.functions,
  login: authService.login,
  logout: authService.logout,
  googleLogin: authService.loginViaGoogle,
  getDownloadLink: authService.getDownloadableURL,
  userDepartments: authService.getUserDepartments
};
