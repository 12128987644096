import { takeEvery, put, call } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import _ from "lodash";
import { jiraActions } from "./actions";
import { JiraData } from "../../services/jiraService/jiraService.type";
import { JiraService } from "../../services/jiraService";
import { errorActions } from "../error/actions";

const formatResponse = (response: Record<string, Record<string, unknown>>) => {
  const data = {};
  _.forEach(response, (each, key) => {
    const objects = _.map(each, (value, key) => {
      return { name: key, value };
    });
    data[key] = objects;
  });
  return data;
};

function* DepartmentWorker(action: Action<string>) {
  try {
    const response = yield call(JiraService.getTicketsData, action.payload);
    yield put(
      jiraActions.getDepartmentData.done({
        params: action.payload,
        result: formatResponse(response.data) as JiraData
      })
    );
  } catch (error) {
    yield put(jiraActions.getDepartmentData.failed(error.message));
    yield put(errorActions.setAppError(error.message));
  }
}

function* OverviewWorker(action: Action<string>) {
  try {
    const response = yield call(JiraService.getTicketsData, action.payload);
    yield put(
      jiraActions.getOverviewData.done({
        params: action.payload,
        result: formatResponse(response.data) as JiraData
      })
    );
  } catch (error) {
    yield put(jiraActions.getOverviewData.failed(error.message));
    yield put(errorActions.setAppError(error.message));
  }
}

function* JiraWatcher(): IterableIterator<any> {
  yield takeEvery(jiraActions.getDepartmentData.started, DepartmentWorker);
  yield takeEvery(jiraActions.getOverviewData.started, OverviewWorker);
}

export default JiraWatcher;
