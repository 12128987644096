import { message } from "antd";

import { API_ERROR_FEEDBACK } from "../constants";

/**
 * Allows a file to be downloaded from a given link or opened in a new tab.
 */

export const downloadFile = (url: string) => {
  try {
    const link = document.createElement("a");
    link.download = url;
    link.rel = "noopener noreferrer";
    link.setAttribute("href", url);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    message.error({ content: API_ERROR_FEEDBACK.downloadError });
  }
};
