import { useMemo, useState } from "react";
// import { downloadFile } from "../helper/downloadFile";
import { AuthService } from "../services/authService";
import { message } from "antd";
import { API_ERROR_FEEDBACK } from "../constants";
import { downloadFile } from "../helper/downloadFile";

/**
 * A hook which helps in simplifying document download.
 * Takes document name as input and downloads the file
 */
const useDocumentDownload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  type documentDownloadType = (data: string, openInTab?: boolean) => void;
  const download: documentDownloadType = async (data, openInTab) => {
    try {
      setLoading(true);
      if (openInTab) {
        // for safari to not treat it as popup, use window.open() before async call
        const windowReference = window.open();
        const response = await AuthService.getDownloadLink(data);
        if (response && windowReference) {
          windowReference.location = response;
        }
      } else {
        const response = await AuthService.getDownloadLink(data);
        downloadFile(response);
      }
      setLoading(false);
    } catch (error) {
      message.error(API_ERROR_FEEDBACK.downloadError);
    }
  };
  return useMemo(() => ({ download, loading }), [download]);
};

export default useDocumentDownload;
