export const SIZES = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

export const API_ERROR_FEEDBACK = {
  loginError: "Could not retrieve user details. Try again",
  userNotFound: "Could not find the user information",
  domainError:
    "Email does not belong to Nexus domain. Try with different email",
  error: "Something went wrong",
  sessionExpired: "Session Expired, please login again",
  downloadError: "Could not download the file."
};

export const HOW_IT_WORKS_PDF_NAME =
  "pdf/Jira Service Desk - Signing up and Using v1.3.pdf";
