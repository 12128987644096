import { AppSettings } from "../../AppSettings";

export const HomeContents = {
  getStartedCard: {
    title: "Get Started",
    content:
      "Every team has their own definition of ‘Done’ and how to get there. A Jira workflow is represented on a digital board and displays the statuses and transitions of every item of work. As your work progresses, team members can access the most accurate information anytime, anywhere."
  },
  jiraServiceDesk: {
    title: "Jira Service Desk"
  },
  ticketPriorities: {
    title: "Ticket Priorities",
    content: `When raising a ticket, you will be asked to specify the priority of the issue. Below are the priorities and their meaning:`,
    subContent: {
      p1Label: "Critical (P1) - ",
      p1Content: "A core system is down affecting large groups of people.",
      p2Label: "High (P2) - ",
      p2Content: "Serious problem that could block progress.",
      p3Label: "Medium (P3) - ",
      p3Content: "Has the potential to affect progress.",
      p4Label: "Low (P4) - ",
      p4Content: "Minor problem or easily worked around.",
      p5Label: "Lowest (P5) - ",
      p5Content: "Trivial problem with little or no impact on progress."
    }
  },
  serviceLevelAgreement: {
    title: "Service Level Agreement",
    subContent: {
      unassignedTimeLabel: "Time Ticket is Unassigned : ",
      unassignedTimeValue: "30 min goal",
      closeAfterResolutionLabel: "Time left to close after resolution: ",
      closeAfterResolutionValue:
        "48 hrs after ticket has been completed, it will be closed",
      responseLabel: "Time to first response: ",
      responseValue:
        "Priority = Medium - 72h Priority = High - 24h Priority = Critical - 4h",
      resolutionLabel: "Time to resolution: ",
      resolutionValue: "Currently ticket has no SLA",
      leftUpdateTimeLabel: "Time left to update/comment: ",
      leftUpdateTimeValue: "120 hrs"
    }
  },
  workflowSystem: {
    title: "Workflow System",
    content: `A workflow consists of an orchestrated and repeatable pattern of activity, enabled by the systematic organisation of resources into processes that transform materials, provide services, or process information. It can be depicted as a sequence of operations, the work of a person or group, the work of an organisation of staff, or one or more simple or complex mechanisms.`
  },
  buttons: {
    login: "Sign in with Google",
    howItWorks: "How it works?",
    mytickets: "My Tickets",
    raiseTicket: "Raise a Ticket",
    showWorkflow: "Show me the flow"
  },
  urls: {
    myTickets: AppSettings.server.myTicketsUrl,
    raiseTicket: AppSettings.server.raiseTicketUrl,
    joinerForm: "forms/Group Nexus Joiner Form.xlsx",
    leaverForm: "forms/Group Nexus Leaver Form.xlsx",
    goingGoogle: AppSettings.server.goingGoogleUrl
  }
};
