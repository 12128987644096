import { ApplicationState } from "./rootReducers";
import { initialState as userState } from "./user/reducers";
import { initialState as jiraState } from "./jira/reducers";
import { initialState as errorState } from "./error/reducers";

export const initialApplicationState: ApplicationState = {
  errorState,
  userState,
  jiraState
};
