import { css } from "@emotion/core";

export const colors = {
  primary: "#44566C",
  darkBlack: "#3C3C3C",
  darkGrey: "#647D9C",
  grey: "#9199a6",
  lightGrey: "#D5D5D5",
  lighterGrey: "#ced2d7",
  white: "#fff",
  red: "#E33147",
  background: "#f4f4f4",
  input: "#8996A6",
  border: "#E4E4E4",
  menuBorder: "#DADADA",
  //feedback colors
  errorFeedbackText: "#721c24",
  errorFeedbackBackground: "#f8d7da",
  errorFeedbackBorder: "#f5c6cb"
};

export const fontSizes = {
  header: "48px",
  title: "34px",
  cardContent: "18px",
  label: "14px",
  department: "16px",
  navMobile: "16px",
  graphXAxisLabel: "16px",
  graphTitle: "34px",
  graphYAxisLabel: "14px",
  subMenu: "12px"
};

export const fonts = {
  regular: "Roboto Regular",
  medium: "Roboto Medium",
  light: "Roboto Light",
  bold: "Roboto Bold"
};

// antd breakpoints
export const breakpoints = {
  xxs: "375px",
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px"
};

export const theme = css({
  "::selection": {
    color: "unset",
    background: "unset"
  },
  ".ant-typography": {
    fontSize: fontSizes.label
  },
  "h1.ant-typography,h2.ant-typography,h3.ant-typography,h4.ant-typography,h5.ant-typography,h6.ant-typography": {
    color: colors.darkBlack,
    fontFamily: fonts.medium,
    fontWeight: 500
  },
  "h1.ant-typography": {
    fontSize: fontSizes.header
  },
  "h2.ant-typography": {
    fontSize: fontSizes.title
  },
  ".ant-card-grid.text-container .main-content": {
    fontSize: fontSizes.cardContent
  },
  ".ant-card-grid .sub-content": {
    span: {
      color: colors.darkGrey
    }
  },
  ".ant-input,.ant-input-password": {
    fontFamily: fonts.medium,
    color: colors.input,
    border: "none",
    fontWeight: 500
  },
  ".priority-graph-container,.status-graph-container": {
    maxWidth: "100%",
    width: "100%"
  },
  [`@media screen and (min-width: ${breakpoints.lg})`]: {
    ".priority-graph-container,.status-graph-container": {
      width: "48%"
    }
  },
  ".loader": {
    height: "80vh"
  },
  ".ant-btn": {
    height: "50px"
  },
  "a.ant-btn": {
    lineHeight: "45px"
  },
  ".ant-modal-body,.ant-drawer-body": {
    backgroundColor: colors.white,
    boxShadow: "0 2px 12px 0 rgba(0,0,0,0.35)",
    borderRadius: "2px",
    ".default-btn ": {
      backgroundColor: colors.white
    }
  },
  ".anticon": {
    color: colors.darkBlack
  },
  //feedback message styles
  ".ant-message-notice-content": {
    padding: 0,
    borderRadius: "0.25rem",
    background: "unset",
    ".ant-message-custom-content": {
      borderRadius: "0.25rem",
      padding: "0.625rem 1rem"
    },
    ".ant-message-error": {
      background: colors.errorFeedbackBackground,
      color: colors.errorFeedbackText,
      border: `solid 1px ${colors.errorFeedbackBorder}`,
      ".anticon": {
        color: colors.errorFeedbackText
      }
    }
  },
  //menu popup
  ".ant-menu-submenu-popup": {
    border: `1px solid ${colors.menuBorder}`,
    borderRadius: "2px",
    boxShadow: "-2px 4px 5px 0 rgba(0,0,0,0.2)",
    ".ant-menu": {
      padding: "0.5rem",
      ".ant-menu-item a": {
        fontSize: fontSizes.label,
        fontFamily: fonts.regular,
        color: colors.darkBlack
      },
      ".ant-menu-item a:hover": {
        color: `${colors.red}`
      },
      ".ant-menu-item-selected": {
        backgroundColor: `${colors.white}`,
        a: {
          color: `${colors.red}`
        }
      }
    },
    ".link-button": {
      height: "unset",
      fontSize: fontSizes.department,
      fontFamily: fonts.medium,
      fontWeight: 500,
      color: colors.darkBlack
    }
  },
  //header
  ".ant-menu-vertical .ant-menu-item": {
    height: "25px",
    lineHeight: "25px",
    fontWeight: "bold",
    marginTop: "8px",
    textAlign: "left",
    letterSpacing: "1px",
    a: {
      fontSize: fontSizes.subMenu
    }
  }
});
