import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useSelector, useDispatch } from "react-redux";
//service
import { JiraData } from "../../services/jiraService/jiraService.type";
//store
import { jiraActions } from "../../store/jira/actions";
import { ApplicationState } from "../../store/rootReducers";

interface Selectors {
  overviewState: JiraData;
}
interface ActionDispatchers {
  getOverviewData: () => void;
}

/**
 * @description accesses Redux, returns required state,actions
 *
 * @returns {(Selectors & ActionDispatchers)}
 */
const useOverview = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);

  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  overviewState: state.jiraState.overviewState
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  getOverviewData: () => {
    dispatch(jiraActions.getOverviewData.started("all"));
  }
});

export default useOverview;
