/** @jsx jsx */
import React, { useEffect, createRef } from "react";
import { jsx } from "@emotion/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";

NoDataToDisplay(Highcharts);

import _ from "lodash";
import { graphObject } from "../../services/jiraService/jiraService.type";
import { colors, fontSizes, fonts } from "../../theme";
import useMobile from "../../helperHooks/useMobile";

interface Props {
  isVertical?: boolean;
  data: graphObject[];
  name?: string;
  loading?: boolean;
  // set the property only when the container has more than one graph
  hasAdjacentGraph?: boolean;
}

const Graph: React.FC<Props> = (props: Props) => {
  const { name, data, isVertical, loading, hasAdjacentGraph } = props;

  const chart = createRef<any>();

  useEffect(() => {
    chart.current.chart.hideNoData();

    if (!loading) {
      chart.current.chart.hideLoading();

      data.length > 0
        ? chart.current.chart.hideNoData()
        : chart.current.chart.showNoData();
    } else {
      chart.current.chart.showLoading();
    }
  }, [loading]);

  const names = _.map(data, "name");
  const values = _.map(data, "value");

  const maxValue = Math.max(...values);

  const stackedValues = values.map((value) => {
    return maxValue - value;
  });

  const isMobile = useMobile();

  const getHeight = () => {
    /*to make the graph component responsive
     ** assign the height to 100% to get the same height
     **if container has more than one graph horizontally
     ** default graph height is 400px */
    if (loading) {
      return "";
    } else if (isVertical) {
      if (hasAdjacentGraph) {
        return "100%";
      } else {
        return "";
      }
    } else if (hasAdjacentGraph) {
      if (values.length > 4) {
        return "100%";
      } else {
        return "";
      }
    } else {
      if (values.length > 3) {
        return values.length * (9600 / window.innerWidth) + "%";
      } else if (values.length == 1) {
        return "200px";
      } else if (values.length == 2) {
        return "300px";
      } else {
        return "";
      }
    }
  };

  const options = {
    loading: {
      hideDuration: 1000,
      showDuration: 1000
    },
    lang: {
      noData: "No data to display"
    },
    noData: {
      style: {
        fontSize: "1rem"
      }
    },
    chart: {
      height: getHeight(),
      spacing: isMobile ? [10, 10, 10, 10] : [30, 30, 30, 30],
      style: {
        borderRadius: "0.4rem"
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: name,
      margin: 30,
      align: "left",
      style: {
        fontSize: fontSizes.graphTitle,
        fontFamily: fonts.medium,
        color: colors.darkBlack
      }
    },
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false
          }
        },
        grouping: false,
        pointWidth: isVertical && !isMobile ? 50 : 25,
        groupPadding: 2,
        borderWidth: 0,
        stacking: "normal"
      }
    },
    xAxis: {
      categories: names,
      labels: {
        x: isVertical && !isMobile ? 0 : 2,
        align: isVertical && !isMobile ? "center" : "left",
        y: isVertical && !isMobile ? 30 : -24,
        style: {
          fontSize: fontSizes.graphXAxisLabel,
          whiteSpace: "nowrap",
          fontFamily: fonts.medium,
          color: colors.darkBlack
        }
      },
      gridLineColor: "transparent",
      lineWidth: 0
    },
    yAxis: {
      gridLineColor: "transparent",
      labels: {
        enabled: false
      },
      title: {
        enabled: false
      },
      lineWidth: 0
    },
    series: [
      {
        data: stackedValues,
        type: isVertical && !isMobile ? "column" : "bar",
        showInLegend: false,
        color: colors.lightGrey
      },
      {
        color: colors.red,
        data: values,
        type: isVertical && !isMobile ? "column" : "bar",
        dataLabels: {
          enabled: true,
          verticalAlign: isVertical && !isMobile ? "top" : "middle",
          align: isVertical && !isMobile ? "center" : "right",
          style: {
            color: colors.white,
            fontSize: fontSizes.graphYAxisLabel,
            textOutline: "none",
            fontFamily: fonts.bold,
            fontWeight: "bold"
          }
        },
        showInLegend: false
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"chart"}
      options={options}
      ref={chart}
    />
  );
};

export default Graph;
