import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

import { firestoreDatabase } from "../../firebase";
import { LoginRequest } from "./authService.type";
import { AppSettings } from "../../AppSettings";

class AuthService {
  public storage: any;
  public functions: any;
  public login(data: LoginRequest) {
    return firestoreDatabase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password);
  }

  public loginViaGoogle() {
    const provider = new firestoreDatabase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account"
    });
    return firestoreDatabase.auth().signInWithPopup(provider);
  }

  public logout() {
    return firestoreDatabase.auth().signOut();
  }

  public getDownloadableURL(url: string) {
    const storageRef = this.storage;
    return storageRef.ref(url).getDownloadURL();
  }

  public getUserDepartments() {
    return this.functions.httpsCallable(AppSettings.server.departmentCF)({});
  }
}

const authService = new AuthService();

export default authService;
