import React from "react";
import { Route, Switch, Redirect, RouteProps } from "react-router-dom";
import PropTypes from "prop-types";
import { routes } from "./Routes";
import _ from "lodash";

import { Spin } from "antd";
import { LoadingOutlined } from "./antdIcons";

import Header from "./components/header";
import Home from "./pages/home/home";
import Overview from "./pages/overview/overview";
import Department from "./pages/department";
import useAuth from "./hooks/authentication/useAuth";

const RoutesContainer: React.FC = () => {
  const {
    isFirebaseLoaded,
    userState: { isLoggedIn, isAdmin }
  } = useAuth();

  const department = (props: any) => {
    const statusProps = props.location.state;
    return (
      <Department
        key={statusProps}
        departmentName={typeof statusProps === "string" ? statusProps : ""}
      />
    );
  };

  department.propTypes = {
    location: PropTypes.shape({
      state: PropTypes.string
    })
  };

  return (
    <Header>
      {!isFirebaseLoaded ? (
        <div className="loader d-flex justify-content-center align-items-center">
          <Spin indicator={<LoadingOutlined />} size="large" />
        </div>
      ) : (
        <Switch>
          <Route path={routes.home.url} exact>
            <Home />
          </Route>
          <PrivateRoute isAuthenticated={isLoggedIn}>
            <GuardedRoute
              path={routes.overview.url}
              exact
              hasAccess={!!isAdmin}
            >
              <Overview />
            </GuardedRoute>
            <GuardedRoute
              path={routes.departments.url}
              exact
              isDepartmentRoute={true} // allow access as per department
              render={department}
            />
            {/* Default route*/}
            <Redirect from="*" to={routes.home.url}></Redirect>
          </PrivateRoute>
        </Switch>
      )}
    </Header>
  );
};

interface PrivateRouteProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps
) => {
  const {
    userState: { isLoggedIn, loading }
  } = useAuth();

  return (
    <Route
      render={({ location }) =>
        props.isAuthenticated ? (
          <Switch>{props.children}</Switch>
        ) : (
          !isLoggedIn &&
          !loading && (
            <Redirect
              to={{ pathname: routes.home.url, state: { from: location } }}
            />
          )
        )
      }
    />
  );
};

type GuardedRouteProps = RouteProps & {
  hasAccess?: boolean;
  isDepartmentRoute?: boolean;
};
export const GuardedRoute: React.FC<GuardedRouteProps> = ({
  hasAccess,
  isDepartmentRoute,
  ...other
}: GuardedRouteProps) => {
  const {
    userState: { department }
  } = useAuth();

  const currentPath = location.pathname.replace("/department", "");
  const hasDepartment = _.some(department, (d) => {
    return d._id === decodeURIComponent(currentPath);
  });

  return hasAccess || (isDepartmentRoute && hasDepartment) ? (
    <Route {...other} />
  ) : (
    <Redirect from="*" to={routes.home.url} />
  );
};

export default RoutesContainer;
