import actionCreatorFactory from "typescript-fsa";

import { JiraData } from "../../services/jiraService/jiraService.type";

const actionCreator = actionCreatorFactory();

const DepartmentActions = actionCreator.async<string, JiraData, Error>(
  "GET_DEPARTMENT_DATA"
);

const OverviewActions = actionCreator.async<string, JiraData, Error>(
  "GET_OVERVIEW_DATA"
);

export const jiraActions = {
  getDepartmentData: DepartmentActions,
  getOverviewData: OverviewActions
};
