/** @jsx jsx */
import React from "react";
import { Card, Typography } from "antd";
import { jsx } from "@emotion/core";

//hooks
import useMobile from "../../helperHooks/useMobile";
import { CardStyles } from "./styles";

const { Title } = Typography;

interface Props {
  title: string;
  content: React.ReactNode;
  image: string;
  imagePlacementLeft?: boolean;
  className?: string;
  footer?: React.ReactNode;
  titleLevel?: 1 | 2 | 3 | 4;
}

/**
 *  Creates custom card component based on the antd card component
 */

const CustomCard: React.FC<Props> = (props: Props) => {
  const {
    title,
    content,
    image,
    className,
    footer,
    imagePlacementLeft,
    titleLevel
  } = props;

  const imageGrid = (
    <Card.Grid
      className="image-container d-flex justify-content-center"
      hoverable={false}
    >
      <img alt={title + "image"} src={image} />
    </Card.Grid>
  );

  const isMobile = useMobile();
  const displayImageFirst = imagePlacementLeft || isMobile;

  return (
    <Card
      data-testid="custom-card"
      className={className}
      css={CardStyles}
      bordered={false}
    >
      {displayImageFirst && imageGrid}
      <Card.Grid className="text-container p-md-3 p-xl-5" hoverable={false}>
        <Title level={titleLevel ? titleLevel : 2}>{title}</Title>
        {content}
        {footer && footer}
      </Card.Grid>
      {!displayImageFirst && imageGrid}
    </Card>
  );
};

export default CustomCard;
