import actionCreatorFactory from "typescript-fsa";
import { UserData } from "../../services/authService/authService.type";
import {
  LoginRequest,
  FirebaseUser
} from "../../services/authService/authService.type";

const actionCreator = actionCreatorFactory();

const LoginActions = actionCreator.async<LoginRequest | void, void, Error>(
  "LOGIN_USER"
);

const LogoutActions = actionCreator.async<void, void, Error>("LOGOUT_USER");

const UserActions = actionCreator.async<FirebaseUser, UserData, Error>(
  "GET_USER_DETAILS"
);

const FlushDataActions = actionCreator("FLUSH_DATA");

const LoadApplicationActions = actionCreator("LOAD_APPLICATION");

export const authActions = {
  loadApplication: LoadApplicationActions,
  loginUser: LoginActions,
  logoutUser: LogoutActions,
  flushData: FlushDataActions,
  userDetails: UserActions
};
