import { all } from "redux-saga/effects";
import JiraWatcher from "./store/jira/saga";
import UserWatcher from "./store/user/saga";

export default function* sagas() {
  yield all([
    // add sagas/watchers
    JiraWatcher(),
    UserWatcher()
  ]);
}
