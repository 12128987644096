/** @jsx jsx */
import React, { useEffect } from "react";
import { Typography } from "antd";
import { jsx } from "@emotion/core";

//components
import Card from "../../components/card";
import Graph from "../../components/graph";

//assests
import { overviewImage } from "../../assets/index";
//hooks
import useOverview from "../../hooks/overview/useOverview";
//constants and styles
import { OverviewContents as CONSTANTS } from "./overviewConstants";
import { OverviewStyles } from "./overviewStyles";
import { SIZES } from "../../constants";

const { Text } = Typography;

const Overview: React.FC = () => {
  const { getOverviewData, overviewState } = useOverview();

  useEffect(() => {
    getOverviewData();
  }, []);

  return (
    <div
      css={OverviewStyles}
      className={"p-1 mx-sm-1 p-sm-3 mx-md-3 mx-lg-3 p-lg-3 mx-xl-4 p-xl-3"}
    >
      <Card
        title={CONSTANTS.ticketOverview.title}
        content={
          <Text className="main-content">
            {CONSTANTS.ticketOverview.content}
          </Text>
        }
        image={overviewImage}
        titleLevel={1}
      ></Card>
      <div className="mt-3 d-flex justify-content-between flex-column flex-lg-row">
        <div className="priority-graph-container">
          <Graph
            isVertical={true}
            name={CONSTANTS.graphTitles.priority}
            data={overviewState.priority}
            loading={overviewState.loading}
            hasAdjacentGraph={
              window.innerWidth > SIZES.lg && overviewState.status.length > 4
            }
          ></Graph>
        </div>
        <div className="mt-3 mt-lg-0 status-graph-container">
          <Graph
            name={CONSTANTS.graphTitles.status}
            data={overviewState.status}
            loading={overviewState.loading}
            hasAdjacentGraph={window.innerWidth > SIZES.lg}
          ></Graph>
        </div>
      </div>
      <div className="my-3 my-md-4">
        <Graph
          name={CONSTANTS.graphTitles.assignee}
          data={overviewState.assignees}
          loading={overviewState.loading}
        ></Graph>
      </div>
      <div className="mt-3 mt-md-4">
        <Graph
          name={CONSTANTS.graphTitles.reporter}
          data={overviewState.reporter}
          loading={overviewState.loading}
        ></Graph>
      </div>
    </div>
  );
};

export default Overview;
