import { css } from "@emotion/core";
import { colors, fontSizes, fonts } from "../../theme";

export const HeaderStyles = css({
  ".ant-layout-content": {
    backgroundColor: colors.background
  },
  "li, .ant-menu-submenu-title": {
    backgroundColor: colors.white,
    fontSize: fontSizes.department,
    fontFamily: fonts.medium,
    fontWeight: 500
  },
  ".header": {
    backgroundColor: colors.white,
    height: "unset",
    lineHeight: "40px",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem"
  },
  ".logo": {
    img: {
      width: "165px",
      backgroundColor: colors.white,
      cursor: "pointer"
    }
  },
  ".hamburger": {
    position: "absolute",
    left: "1rem",
    svg: {
      fontSize: "22px",
      marginTop: "0.3rem"
    }
  },
  ".menu": {
    ".ant-menu-item, .ant-menu-submenu": {
      border: "none"
    },
    ".ant-menu-item:hover, .ant-menu-submenu:hover": {
      background: colors.white,
      border: "none"
    }
  },
  ".user-icon-menu": {
    ".ant-menu-submenu-title": {
      paddingRight: 0,
      marginRight: 0
    },
    img: {
      maxWidth: "100%"
    }
  }
});

export const drawerStyle = css({
  ".ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected": {
    backgroundColor: `${colors.white}`
  },
  ".ant-menu-item::after": {
    borderRight: "none"
  },
  "li, .link-button": {
    color: colors.darkBlack,
    textTransform: "uppercase"
  },
  "a, .ant-menu-submenu-title, .link-button": {
    fontWeight: "bold",
    fontSize: fontSizes.navMobile,
    letterSpacing: "1.33px"
  },
  ".ant-menu": {
    backgroundColor: "unset"
  },
  ".ant-menu-submenu": {
    paddingTop: "0.4rem"
  },
  ".ant-menu-sub": {
    paddingTop: "0.2em",
    ".ant-menu-item": {
      border: "none",
      a: {
        fontSize: fontSizes.department
      }
    }
  },
  ".ant-drawer-header": {
    borderBottom: "1px solid",
    borderColor: colors.grey,
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "center",
    padding: "3rem 2rem 1.5rem 2rem"
  },
  ".ant-drawer-body": {
    padding: "0",
    boxShadow: "none",
    ".ant-menu-submenu-title": {
      position: "relative",
      left: "-0.5rem",
      border: "none",
      content: '""',
      display: "block",
      margin: "0 auto",
      width: "90%",
      padding: "1rem 0 3.5rem 0"
    },
    ".ant-menu-submenu": {
      ul: {
        ".ant-menu-item": {
          border: "none",
          a: {
            fontWeight: "lighter"
          }
        }
      }
    },
    ".ant-menu-root": {
      ".ant-menu-item,.ant-menu-submenu": {
        "a, .link-button": {
          position: "relative",
          left: "-0.5rem"
        },
        padding: "1rem 0 3.5rem 0",
        borderBottom: "2px solid",
        borderColor: colors.lighterGrey,
        content: '""',
        display: "block",
        margin: "0 auto",
        width: "90%"
      },
      ".ant-menu-submenu": {
        padding: 0,
        ".ant-menu-submenu-title": {
          //not possible to override without !important as line-indent is mandatory for inline menu
          paddingLeft: "0 !important"
        }
      }
    },
    ".ant-menu-sub .ant-menu-item": {
      padding: "0px 0px 0px 1rem",
      fontFamily: fonts.medium
    }
  },
  img: {
    width: "165px"
  },
  ".ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow": {
    position: "absolute",
    top: "44%",
    width: "10px",
    marginLeft: "1rem",
    right: "unset"
  }
});
