import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { routes } from "../Routes";

export type RedirectSelector = (
  routeSelector: string | ((route: typeof routes) => string),
  state?: string
) => void;

/**
 * A reusable hook to redirect to a different route
 */
const useRedirect = () => {
  const history = useHistory();

  const redirect: RedirectSelector = useCallback(
    (
      routeSelector: string | ((route: typeof routes) => string),
      state?: string
    ) => {
      const route =
        typeof routeSelector === "string"
          ? routeSelector
          : routeSelector(routes);
      history.push(route, state);
    },
    [history]
  );

  return redirect;
};

export default useRedirect;
