import { combineReducers, AnyAction } from "redux";

// State
import { JiraState } from "./jira/state";
import { UserState } from "./user/state";
import { ErrorState } from "./error/state";

// Reducers
import { JiraReducer } from "./jira/reducers";
import { UserReducer } from "./user/reducers";
import { authActions } from "./user/actions";
import { initialApplicationState } from "./initialState";
import { ErrorReducer } from "./error/reducers";

export interface ApplicationState {
  errorState: ErrorState;
  jiraState: JiraState;
  userState: UserState;
}

const rootReducer = combineReducers<ApplicationState>({
  errorState: ErrorReducer,
  jiraState: JiraReducer,
  userState: UserReducer
});

export default (state: ApplicationState | undefined, action: AnyAction) => {
  return action.type === authActions.flushData.type
    ? rootReducer(initialApplicationState, action)
    : rootReducer(state, action);
};
