/** @jsx jsx */
import React, { useState } from "react";
import { Typography, Space, Modal } from "antd";
import { jsx } from "@emotion/core";

//components
import Card from "../../components/card";
import CustomButton from "../../components/button";
//assests
import {
  getStartedImage,
  ticketPriorityImage,
  serviceLevelImage,
  workflowImage,
  ticketProcessImage,
  goingGoogleImage,
  logo,
  googleFavicon
} from "../../assets/index";
//hooks
import useAuth from "../../hooks/authentication/useAuth";
import useMobile from "../../helperHooks/useMobile";
import useDocumentDownload from "../../helperHooks/useDownload";
//constants and styles
import { HOW_IT_WORKS_PDF_NAME } from "../../constants";
//import Login from "../login/login";
import { HomeContents as CONSTANTS } from "./homeConstants";
import { HomeStyles, WorkflowModalStyles } from "./homeStyles";

const { Text, Title } = Typography;
const Home: React.FC = () => {
  const [isWorkflowOpen, setWorkflow] = useState<boolean>(false);
  //part of future release
  // const [isLogin, setLogin] = useState<boolean>(false);
  const { userState, loginUser } = useAuth();
  const isMobile = useMobile();
  const { download, loading } = useDocumentDownload();

  const getStartedContent = (
    <Text className="main-content">{CONSTANTS.getStartedCard.content}</Text>
  );
  const getStartedFooter = (
    <Space
      className="mt-2 button-container"
      direction="horizontal"
      size="small"
    >
      <CustomButton
        type="primary"
        containerDivClassName="login-button mr-lg-3 mr-xl-2"
        className="px-3 px-md-1 px-lg-2 px-xxl-3"
        loading={userState.loading}
        onClick={() => loginUser()}
      >
        <img
          alt="google favicon"
          className="mr-2 mr-md-1 mr-lg-2"
          src={googleFavicon}
        ></img>
        {CONSTANTS.buttons.login}
      </CustomButton>
      <CustomButton
        className="how-it-works px-4 px-md-1 px-lg-2 px-xl-3 px-xxl-4"
        onClick={() => download(HOW_IT_WORKS_PDF_NAME, true)}
        loading={loading}
      >
        {CONSTANTS.buttons.howItWorks}
      </CustomButton>
    </Space>
  );

  const jiraServiceFooter = (
    <Space className="mt-3 d-flex" direction="horizontal" size={"large"}>
      <CustomButton
        type="primary"
        href={CONSTANTS.urls.myTickets}
        target="_blank"
      >
        {CONSTANTS.buttons.mytickets}
      </CustomButton>
      <CustomButton
        className="px-4"
        href={CONSTANTS.urls.raiseTicket}
        target="_blank"
      >
        {CONSTANTS.buttons.raiseTicket}
      </CustomButton>
    </Space>
  );

  const ticketPrioritiesContent = (
    <Space className="d-flex" direction="vertical" size={"large"}>
      <Text className="main-content">{CONSTANTS.ticketPriorities.content}</Text>
      <Typography className="sub-content">
        <Text>{CONSTANTS.ticketPriorities.subContent.p1Label}</Text>
        <Text className="card-bold-text">
          {CONSTANTS.ticketPriorities.subContent.p1Content}
        </Text>
        <br />
        <Text>{CONSTANTS.ticketPriorities.subContent.p2Label}</Text>
        <Text className="card-bold-text">
          {CONSTANTS.ticketPriorities.subContent.p2Content}
        </Text>
        <br />
        <Text>{CONSTANTS.ticketPriorities.subContent.p3Label}</Text>
        <Text className="card-bold-text">
          {CONSTANTS.ticketPriorities.subContent.p3Content}
        </Text>
        <br />
        <Text>{CONSTANTS.ticketPriorities.subContent.p4Label}</Text>
        <Text className="card-bold-text">
          {CONSTANTS.ticketPriorities.subContent.p4Content}
        </Text>
        <br />
        <Text>{CONSTANTS.ticketPriorities.subContent.p5Label}</Text>
        <Text className="card-bold-text">
          {CONSTANTS.ticketPriorities.subContent.p5Content}
        </Text>
      </Typography>
    </Space>
  );

  const serviceLevelContent = (
    <Typography className="sub-content">
      <Text>
        {CONSTANTS.serviceLevelAgreement.subContent.unassignedTimeLabel}
      </Text>
      <Text className="card-bold-text">
        {CONSTANTS.serviceLevelAgreement.subContent.unassignedTimeValue}
      </Text>
      <br />
      <Text>
        {CONSTANTS.serviceLevelAgreement.subContent.closeAfterResolutionLabel}
      </Text>
      <Text className="card-bold-text">
        {CONSTANTS.serviceLevelAgreement.subContent.closeAfterResolutionValue}
      </Text>
      <br />
      <Text>{CONSTANTS.serviceLevelAgreement.subContent.responseLabel}</Text>
      <Text className="card-bold-text">
        {CONSTANTS.serviceLevelAgreement.subContent.responseValue}
      </Text>
      <br />
      <Text>{CONSTANTS.serviceLevelAgreement.subContent.resolutionLabel}</Text>
      <Text className="card-bold-text">
        {CONSTANTS.serviceLevelAgreement.subContent.resolutionValue}
      </Text>
      <br />
      <Text>
        {CONSTANTS.serviceLevelAgreement.subContent.leftUpdateTimeLabel}
      </Text>
      <Text className="card-bold-text">
        {CONSTANTS.serviceLevelAgreement.subContent.leftUpdateTimeValue}
      </Text>
    </Typography>
  );

  const workflowContent = (
    <Text className="main-content">{CONSTANTS.workflowSystem.content}</Text>
  );
  const workflowFooter = (
    <CustomButton
      type="link"
      data-testid="show-workflow"
      danger
      className="show-flow-btn"
      onClick={() => setWorkflow(true)}
    >
      {CONSTANTS.buttons.showWorkflow}
    </CustomButton>
  );

  const workflowModal = (
    <Modal
      width={isMobile ? "100%" : "70%"}
      centered={true}
      visible={isWorkflowOpen && !!ticketPriorityImage}
      onCancel={() => setWorkflow(false)}
      footer={null}
      css={WorkflowModalStyles}
      title={
        <img width={isMobile ? "25%" : "15%"} alt={"Nexus logo"} src={logo} />
      }
    >
      <img alt={"ticket process image"} src={ticketProcessImage} />
    </Modal>
  );

  return (
    <div
      css={HomeStyles}
      className={"p-1 mx-sm-1 p-sm-3 mx-lg-3 p-lg-3 mx-xl-5 p-xl-5"}
    >
      <Card
        title={
          userState.isLoggedIn
            ? CONSTANTS.jiraServiceDesk.title
            : CONSTANTS.getStartedCard.title
        }
        content={getStartedContent}
        image={getStartedImage}
        className={
          userState.isLoggedIn ? "jira-service-card" : "get-started-card"
        }
        titleLevel={2}
        footer={userState.isLoggedIn ? jiraServiceFooter : getStartedFooter}
      ></Card>
      {userState.isLoggedIn && (
        <div className="text-center p-3 joiner-leaver-container my-3">
          <Title level={2}>
            <a
              href={CONSTANTS.urls.goingGoogle}
              rel="noreferrer"
              target="_blank"
            >
              GoingGoogle!
            </a>
          </Title>
          <div className="d-flex justify-content-center mt-5">
            <a
              href={CONSTANTS.urls.goingGoogle}
              rel="noreferrer"
              target="_blank"
            >
              <img
                alt="download-form-image"
                src={goingGoogleImage}
                className="mr-3 going-google"
              />
            </a>
          </div>
        </div>
      )}
      <Card
        title={CONSTANTS.ticketPriorities.title}
        content={ticketPrioritiesContent}
        image={ticketPriorityImage}
        imagePlacementLeft={true}
      ></Card>
      <Card
        title={CONSTANTS.serviceLevelAgreement.title}
        content={serviceLevelContent}
        image={serviceLevelImage}
      ></Card>
      <Card
        title={CONSTANTS.workflowSystem.title}
        content={workflowContent}
        image={workflowImage}
        imagePlacementLeft={true}
        footer={workflowFooter}
      ></Card>
      {workflowModal}
      {/* part of future release */}
      {/* <Login
        isLoginOpen={isLogin}
        onClose={() => {
          setLogin(false);
        }}
      /> */}
    </div>
  );
};

export default Home;
