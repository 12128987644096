import { css } from "@emotion/core";
import { colors, fonts, fontSizes, breakpoints } from "../../theme";

export const HomeStyles = css({
  ".going-google": {
    width: "75%"
  },
  ".button-container": {
    display: "flex",
    [`@media screen and (max-width: ${breakpoints.xxs})`]: {
      display: "block",
      ".how-it-works": {
        marginTop: "0.5em"
      }
    }
  },
  ".show-flow-btn": {
    textTransform: "unset",
    padding: 0,
    height: "unset",
    marginTop: "1rem"
  },
  ".login-button .anticon-loading": {
    color: colors.white
  },
  ".show-flow-btn.ant-btn-link": {
    borderBottom: "1px solid"
  },
  ".joiner-leaver-container": {
    textAlign: "center",
    ".ant-btn": {
      height: "auto",
      whiteSpace: "pre-line",
      textTransform: "none",
      border: `1px solid ${colors.border}`,
      borderRadius: "8px",
      backgroundColor: colors.white,
      boxShadow: "0 2px 10px 0 rgba(0,0,0,0.18)",
      padding: "1rem 1.5rem",
      display: "flex",
      alignItems: "center",
      span: {
        font: `${fontSizes.cardContent} ${fonts.medium}`,
        fontWeight: 500
      }
    }
  },
  ".card-bold-text": {
    fontFamily: fonts.medium
  },
  ".login-button button": {
    textTransform: "unset"
  }
});

export const WorkflowModalStyles = css({
  ".ant-modal-body": {
    display: "flex",
    justifyContent: "center",
    img: {
      maxWidth: "100%"
    }
  }
});
