/** @jsx jsx */

import React from "react";
import { Button } from "antd";
import { jsx, SerializedStyles } from "@emotion/core";

import { ButtonProps } from "antd/es/button";
import { ButtonStyles } from "./styles";

interface Props extends ButtonProps {
  containerDivStyle?: SerializedStyles;
  containerDivClassName?: string;
}

/**
 *  Creates button with type specified, accepts all the Button ant design props
 *  along with custom props. Styles the button with a predefined stylesheet
 *  consistent with buttons across application
 */

const CustomButton: React.FC<Props> = ({
  children,
  type,
  size,
  containerDivClassName,
  containerDivStyle,
  className,
  ...restProps
}: Props) => {
  return (
    <div
      data-testid="custom-button-container"
      css={[ButtonStyles, containerDivStyle]}
      className={`d-flex ${containerDivClassName || ""}`}
    >
      <Button
        type={type}
        size={size || "large"}
        className={`
        ${!type || type === "default" ? "default-btn " : ""}
        ${
          className ||
          (size
            ? size === "small"
              ? "px-md-3"
              : size === "middle"
              ? "px-1 px-md-4"
              : "px-2 px-md-5"
            : "px-3 px-md-5")
        }`}
        {...restProps}
      >
        {children}
      </Button>
    </div>
  );
};

export default CustomButton;
