import { css } from "@emotion/core";
import { colors, fonts, fontSizes } from "../../theme";

export const ButtonStyles = css({
  fontFamily: fonts.medium,
  "button,a": {
    textTransform: "uppercase",
    fontSize: fontSizes.label,
    borderRadius: "2px"
  },
  ".default-btn": {
    color: colors.darkBlack,
    border: `2px solid ${colors.darkBlack}`
  },
  ".ant-btn-primary": {
    background: colors.darkBlack,
    border: `2px solid ${colors.darkBlack}`
  }
});
