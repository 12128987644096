//set URL's in .env file
const BaseUrl = process.env.REACT_APP_BASE_URL as string;
const JiraMyTicketsUrl = process.env.REACT_APP_JIRA_MY_TICKETS_URL as string;
const JiraRaiseTicketUrl = process.env
  .REACT_APP_JIRA_RAISE_TICKET_URL as string;
const goingGoogleUrl = process.env.REACT_APP_GOING_GOOGLE_URL as string;
const AppStorage = process.env.REACT_APP_STORAGE as string;
const AppRegion = process.env.REACT_APP_REGION as string;
const DeptCF = process.env.REACT_APP_DEPARTMENT_CF as string;
const JiraCF = process.env.REACT_APP_JIRA_CF as string;

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl,
    myTicketsUrl: JiraMyTicketsUrl,
    raiseTicketUrl: JiraRaiseTicketUrl,
    goingGoogleUrl: goingGoogleUrl,
    appRegion: AppRegion,
    appStorage: AppStorage,
    departmentCF: DeptCF,
    jiraCF: JiraCF
  };
  public static isDev = () => process.env.NODE_ENV === "development";
  public static isProduction = () => process.env.NODE_ENV === "production";
  public static isTest = () => process.env.NODE_ENV === "test";
}
