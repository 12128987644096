import { useState, useEffect } from "react";
import { debounce } from "lodash";

import { SIZES } from "../constants";

/**
 * a hook with value isMobile
 */
const useMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  //Using debounce to accept only one width change every 100ms,
  //Preventing unnecessary re-renders
  useEffect(() => {
    const updateWidth = debounce(() => setWidth(window.innerWidth), 100);

    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return width <= SIZES.md;
};

export default useMobile;
