export const OverviewContents = {
  ticketOverview: {
    title: "Ticket Overview",
    content:
      "Every team has their own definition of ‘Done’ and how to get there. A Jira workflow is represented on a digital board and displays the statuses and transitions of every item of work. As your work progresses, team members can access the most accurate information anytime, anywhere."
  },
  graphTitles: {
    priority: "Priority",
    status: "Status",
    assignee: "Number of Tickets - Assignee",
    reporter: "Number of Tickets - Reporter"
  }
};
