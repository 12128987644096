/** @jsx jsx */
import React, { useState, ReactNode } from "react";
import { jsx } from "@emotion/core";

import { Drawer, Menu, Layout, Avatar, Button } from "antd";
import { MenuMode } from "antd/lib/menu";

import { routes } from "../../Routes";

//hooks
import useAuth from "../../hooks/authentication/useAuth";
import useMobile from "../../helperHooks/useMobile";
import useRedirect from "../../helperHooks/useRedirect";
import useDocumentDownload from "../../helperHooks/useDownload";
//constants
import { HOW_IT_WORKS_PDF_NAME } from "../../constants";
import { HeaderContents as CONSTANTS } from "./headerConstants";

import { logo, userProfileImage } from "../../assets/index";
import { MenuOutlined } from "../../antdIcons";

// import Login from "../../pages/login/login";
import { HeaderStyles, drawerStyle } from "./headerStyles";

interface Props {
  children?: ReactNode;
}

const Header: React.FC<Props> = (props: Props) => {
  const [visible, setIsVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(window.location.pathname);
  //part of future release
  // const [isLogin, setLogin] = useState<boolean>(false);

  const { userState, loginUser, logoutUser } = useAuth();
  const redirect = useRedirect();
  const { download, loading } = useDocumentDownload();

  const isMobile = useMobile();

  const showMenu = () => {
    setIsVisible(true);
  };

  const closeMenu = () => {
    setIsVisible(false);
  };

  const handleLinkClick = (routeClicked: string, name?: string) => () => {
    setSelectedTab(routeClicked);
    setIsVisible(false);
    redirect(routeClicked, name);
  };

  const getDepartmentRoute = (id: string) => {
    return `/department${id}`;
  };

  const logout = () => {
    logoutUser();
    setSelectedTab("/home");
  };

  const getMenuItems = (orientation: MenuMode) => {
    return (
      <div>
        {
          <Menu
            data-testid="header-menu"
            selectedKeys={[selectedTab]}
            mode={orientation}
            inlineIndent={15}
          >
            <Menu.Item key={routes.home.url}>
              <a onClick={handleLinkClick(routes.home.url)}>Home</a>
            </Menu.Item>
            {userState.isAdmin && (
              <Menu.Item key={routes.overview.url}>
                <a onClick={handleLinkClick(routes.overview.url)}>Overview</a>
              </Menu.Item>
            )}
            {userState.department &&
              (userState.department.length > 1 ? (
                <Menu.SubMenu title="Departments">
                  {userState.department.map((department) => {
                    return (
                      <Menu.Item key={`/department${department._id}`}>
                        <a
                          onClick={handleLinkClick(
                            getDepartmentRoute(department._id),
                            department.displayName
                          )}
                        >
                          {department.displayName}
                        </a>
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={`/department${userState.department[0]._id}`}>
                  <a
                    onClick={handleLinkClick(
                      getDepartmentRoute(userState.department[0]._id),
                      userState.department[0].displayName
                    )}
                  >
                    {userState.department[0].displayName}
                  </a>
                </Menu.Item>
              ))}
            {isMobile && (
              <Menu.Item key="howitworks">
                <Button
                  type="link"
                  className="link-button p-0"
                  onClick={() => download(HOW_IT_WORKS_PDF_NAME, true)}
                  loading={loading}
                >
                  {CONSTANTS.buttons.howItWorks}
                </Button>
              </Menu.Item>
            )}
            {isMobile ? (
              <Menu.Item>
                {userState.isLoggedIn ? (
                  <a onClick={logout}>{CONSTANTS.buttons.logout}</a>
                ) : (
                  <a onClick={() => loginUser()}>{CONSTANTS.buttons.login}</a>
                )}
              </Menu.Item>
            ) : (
              <Menu.SubMenu
                className="user-icon-menu mr-0"
                data-testid="user-menu"
                title={
                  <Avatar src={userState.photoUrl || userProfileImage}></Avatar>
                }
              >
                <Menu.Item
                  data-testid="dropdown-item"
                  key={userState.isLoggedIn ? "logout" : "login"}
                >
                  {userState.isLoggedIn ? (
                    <a onClick={logout}>{CONSTANTS.buttons.logout}</a>
                  ) : (
                    <a onClick={() => loginUser()}>{CONSTANTS.buttons.login}</a>
                  )}
                </Menu.Item>
                <Menu.Item key="howitworks">
                  <Button
                    type="link"
                    className="link-button p-0"
                    onClick={() => download(HOW_IT_WORKS_PDF_NAME, true)}
                    loading={loading}
                  >
                    {CONSTANTS.buttons.howItWorks}
                  </Button>
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu>
        }
      </div>
    );
  };

  return (
    <Layout css={HeaderStyles}>
      <Layout.Header
        data-testid="header"
        className="header px-md-3 px-lg-4 px-xl-5"
      >
        {isMobile ? (
          <nav className="d-flex justify-content-center p-3">
            <div
              data-testid="hamburger"
              className="hamburger d-flex"
              onClick={showMenu}
            >
              <MenuOutlined />
            </div>

            <Drawer
              css={drawerStyle}
              title={
                <div className="drawer-logo">
                  <img alt="Logo" src={logo} />
                </div>
              }
              width={340}
              placement="left"
              closable={false}
              onClose={closeMenu}
              visible={visible}
              footer={null}
            >
              {getMenuItems("inline")}
            </Drawer>

            <a href="/" data-testid="hamburger-logo" className="d-flex logo">
              <img alt="Logo" src={logo} />
            </a>
          </nav>
        ) : (
          <nav className="d-flex justify-content-between p-1 px-md-2 py-md-3">
            <a href="/" className="d-flex logo">
              <img alt="Logo" src={logo} />
            </a>
            <div className="menu">{getMenuItems("horizontal")}</div>
          </nav>
        )}
      </Layout.Header>
      <Layout.Content>{props.children}</Layout.Content>
      {/* part of future release */}
      {/* <Login
        isLoginOpen={isLogin}
        onClose={() => {
          setLogin(false);
        }}
      /> */}
    </Layout>
  );
};

export default Header;
