export const DepartmentContents = {
  title: (name: string) =>
    `The ${name} page displays all ticketing data raised by members of the ${name} team`,
  graphTitles: {
    priority: "Priority",
    status: "Status",
    assignee: "Number of Tickets - Assignee",
    reporter: "Number of Tickets - Reporter"
  }
};
