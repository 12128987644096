import actionCreatorFactory from "typescript-fsa";

const actionCreator = actionCreatorFactory();

const ErrorActions = actionCreator<string>("SET_APPLICATION_ERROR");

const ErrorStatusActions = actionCreator("SET_ERROR_DISPLAY");

export const errorActions = {
  setAppError: ErrorActions,
  setErrorStatus: ErrorStatusActions
};
