import "firebase/firestore";

import * as firebase from "firebase/app";
import { AppSettings } from "./AppSettings";
import { AuthService } from "./services/authService";

if (AppSettings.isDev()) {
  const firebaseConfigGN = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  firebase.initializeApp(firebaseConfigGN);
} else if (AppSettings.isProduction()) {
  const firebaseConfigGN = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };
  firebase.initializeApp(firebaseConfigGN);
  // (async function () {
  //   const response = await fetch("/__/firebase/init.json");
  //   console.log(response);
  //   const automaticConfigObject = response.json();
  //   firebase.initializeApp(automaticConfigObject);
  // })();
} else if (AppSettings.isTest()) {
  firebase.initializeApp({
    apikey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSensorId: "",
    appId: "",
    measurementId: ""
  });
}
if (!AppSettings.isTest()) {
  AuthService.storage = firebase.app().storage(AppSettings.server.appStorage);
  AuthService.functions = firebase
    .app()
    .functions(AppSettings.server.appRegion);
}
export const firestoreDatabase = firebase;
