import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store/rootReducers";
import { errorActions } from "../../store/error/actions";

interface Selectors {
  error: string;
  isDisplayed: boolean;
}
interface ActionDispatchers {
  updateErrorStatus: () => void;
}

/**
 * @description accesses Redux, returns required state,actions
 *
 * @returns {(Selectors & ActionDispatchers)}
 */
const useError = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);
  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  error: state.errorState.error,
  isDisplayed: state.errorState.isDisplayed
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  updateErrorStatus: () => {
    dispatch(errorActions.setErrorStatus());
  }
});

export default useError;
