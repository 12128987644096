/** @jsx jsx */
import React, { useEffect } from "react";
import { Typography } from "antd";
import { jsx } from "@emotion/core";

import { useParams } from "react-router-dom";
//components
import Graph from "../../components/graph";

//hooks
import useDepartment from "../../hooks/departments/useDepartment";

//constants and styles
import { DepartmentContents as CONSTANTS } from "./departmentConstants";
import { DepartmentStyles } from "./departmentStyles";
import { SIZES } from "../../constants";

const { Title } = Typography;

interface Props {
  departmentName: string;
}

const Department: React.FC<Props> = (props: Props) => {
  const { id } = useParams();

  const { getDepartmentData, departmentState } = useDepartment();

  useEffect(() => {
    getDepartmentData(id);
  }, [id]);

  return (
    <div
      data-testid="department"
      css={DepartmentStyles}
      className={"p-1 mx-sm-1 p-sm-3 mx-md-3 mx-lg-3 p-lg-3 mx-xl-4 p-xl-3"}
    >
      <Title level={2}>{CONSTANTS.title(props.departmentName)}</Title>

      <div className="mt-3 d-flex justify-content-between flex-column flex-lg-row">
        <div className="priority-graph-container">
          <Graph
            data-testid="priority-graph"
            isVertical={true}
            name={CONSTANTS.graphTitles.priority}
            data={departmentState.priority}
            loading={departmentState.loading}
            hasAdjacentGraph={
              window.innerWidth > SIZES.lg && departmentState.status.length > 4
            }
          ></Graph>
        </div>
        <div className="mt-3 mt-lg-0 status-graph-container">
          <Graph
            name={CONSTANTS.graphTitles.status}
            data={departmentState.status}
            loading={departmentState.loading}
            hasAdjacentGraph={window.innerWidth > SIZES.lg}
          ></Graph>
        </div>
      </div>
      <div className="my-3 my-md-4">
        <Graph
          name={CONSTANTS.graphTitles.assignee}
          data={departmentState.assignees}
          loading={departmentState.loading}
        ></Graph>
      </div>
      <div className="mt-3 mt-md-4">
        <Graph
          name={CONSTANTS.graphTitles.reporter}
          data={departmentState.reporter}
          loading={departmentState.loading}
        ></Graph>
      </div>
    </div>
  );
};

export default Department;
