import { reducerWithInitialState } from "typescript-fsa-reducers";
import { authActions } from "./actions";
import { UserState } from "./state";

export const initialState: UserState = {
  isFirebaseLoaded: false,
  userData: { loading: false, isLoggedIn: false }
};

export const UserReducer = reducerWithInitialState(initialState)
  .case(authActions.loadApplication, (state) => ({
    ...state,
    isFirebaseLoaded: true
  }))
  .case(authActions.loginUser.started, (state) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: true
    }
  }))
  .case(authActions.loginUser.done, (state) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: false,
      error: undefined
    }
  }))
  .case(authActions.loginUser.failed, (state, errorMessage) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: false,
      error: errorMessage
    }
  }))
  .case(authActions.logoutUser.started, (state) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: true
    }
  }))
  .case(authActions.logoutUser.done, (state) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: false,
      error: undefined
    }
  }))
  .case(authActions.logoutUser.failed, (state, errorMessage) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: false,
      error: errorMessage
    }
  }))
  .case(authActions.userDetails.started, (state) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: true
    }
  }))
  .case(authActions.userDetails.done, (state, response) => ({
    ...state,
    userData: {
      ...response.result,
      loading: false,
      error: undefined
    }
  }))
  .case(authActions.userDetails.failed, (state, errorMessage) => ({
    ...state,
    userData: {
      ...state.userData,
      loading: false,
      error: errorMessage
    }
  }));
