import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useSelector, useDispatch } from "react-redux";
//services
import { UserData } from "../../services/authService/authService.type";
import {
  LoginRequest,
  FirebaseUser
} from "../../services/authService/authService.type";
//store
import { authActions } from "../../store/user/actions";
import { ApplicationState } from "../../store/rootReducers";

interface Selectors {
  userState: UserData;
  isFirebaseLoaded: boolean;
}
interface ActionDispatchers {
  loginUser: (payload: LoginRequest | void) => void;
  logoutUser: () => void;
  getUserDetails: (payload: FirebaseUser) => void;
  updateFirebaseStatus: () => void;
}

/**
 * @description accesses Redux, returns required state,actions
 *
 * @returns {(Selectors & ActionDispatchers)}
 */
const useAuth = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);
  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  userState: state.userState.userData,
  isFirebaseLoaded: state.userState.isFirebaseLoaded
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  loginUser: (payload: LoginRequest | void) => {
    dispatch(authActions.loginUser.started(payload));
  },
  logoutUser: () => {
    dispatch(authActions.logoutUser.started());
  },
  getUserDetails: (payload: FirebaseUser) => {
    dispatch(authActions.userDetails.started(payload));
  },
  updateFirebaseStatus: () => {
    dispatch(authActions.loadApplication());
  }
});

export default useAuth;
