import { reducerWithInitialState } from "typescript-fsa-reducers";
import { jiraActions } from "./actions";
import { JiraState } from "./state";

const initialValue = {
  priority: [],
  status: [],
  assignees: [],
  reporter: [],
  loading: false
};
export const initialState: JiraState = {
  departmentState: initialValue,
  overviewState: initialValue
};
export const JiraReducer = reducerWithInitialState(initialState)
  .case(jiraActions.getOverviewData.started, (state) => ({
    ...state,
    overviewState: {
      ...initialState.overviewState,
      loading: true
    }
  }))
  .case(jiraActions.getOverviewData.done, (state, response) => ({
    ...state,
    overviewState: {
      ...response.result,
      loading: false,
      error: undefined
    }
  }))
  .case(jiraActions.getOverviewData.failed, (state, errorMessage) => ({
    ...state,
    overviewState: {
      ...state.overviewState,
      loading: false,
      error: errorMessage
    }
  }))
  .case(jiraActions.getDepartmentData.started, (state) => ({
    ...state,
    departmentState: {
      ...initialState.departmentState,
      loading: true
    }
  }))
  .case(jiraActions.getDepartmentData.done, (state, response) => ({
    ...state,
    departmentState: {
      ...response.result,
      loading: false,
      error: undefined
    }
  }))
  .case(jiraActions.getDepartmentData.failed, (state, errorMessage) => ({
    ...state,
    departmentState: {
      ...state.departmentState,
      loading: false,
      error: errorMessage
    }
  }));
