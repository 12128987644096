//all the routes in this application is defined here.
export const routes = {
  home: {
    url: "/home"
  },
  overview: {
    url: "/overview"
  },
  departments: {
    url: "/department/:id"
  }
};
