import { AuthService } from "../authService";
import { AppSettings } from "../../AppSettings";

class JiraService {
  public getTicketsData(unit: string) {
    return AuthService.functions.httpsCallable(
      AppSettings.server.jiraCF + "?orgUnitPath=" + encodeURIComponent(unit)
    )({});
  }
}
const jiraService = new JiraService();

export default jiraService;
