import { reducerWithInitialState } from "typescript-fsa-reducers";
import { errorActions } from "./actions";
import { ErrorState } from "./state";

export const initialState: ErrorState = {
  error: "",
  isDisplayed: true
};

export const ErrorReducer = reducerWithInitialState(initialState)
  .case(errorActions.setAppError, (state, errorMessage) => ({
    ...state,
    error: errorMessage,
    isDisplayed: false
  }))
  .case(errorActions.setErrorStatus, (state) => ({
    ...state,
    isDisplayed: true
  }));
