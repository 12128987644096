import { takeEvery, put, call } from "redux-saga/effects";
import { Action } from "typescript-fsa";

import { authActions } from "./actions";
import { UserData } from "../../services/authService/authService.type";
import {
  LoginRequest,
  FirebaseUser
} from "../../services/authService/authService.type";
import { AuthService } from "../../services/authService";
import { API_ERROR_FEEDBACK } from "../../constants";
import { errorActions } from "../error/actions";

function IsNexusDomain(mail: string) {
  return mail.split("@")[1] === "groupnexus.co.uk";
}

function* LoginWorker(action: Action<LoginRequest | void>) {
  try {
    let response;
    if (action.payload) {
      response = yield call(AuthService.login, action.payload);
    } else {
      response = yield call(AuthService.googleLogin);
    }
    if (response?.user) {
      yield put(
        authActions.loginUser.done({
          params: action.payload
        })
      );
      yield authActions.userDetails.started(response.user.email);
    } else {
      throw Error(API_ERROR_FEEDBACK.loginError);
    }
  } catch (error) {
    //ignore the popup closed error message from firebase
    const message =
      error?.code === "auth/popup-closed-by-user" ? "" : error.message;
    yield put(authActions.loginUser.failed(message));
    yield put(errorActions.setAppError(message));
  }
}

function* LogoutWorker() {
  try {
    yield call(AuthService.logout);
    yield put(authActions.flushData());
    yield put(authActions.logoutUser.done({}));
  } catch (error) {
    yield put(authActions.logoutUser.failed(error.message));
    yield put(errorActions.setAppError(error.message));
  }
}

function* UserDetailsWatcher(action: Action<FirebaseUser>) {
  try {
    if (!IsNexusDomain(action.payload.email)) {
      //logout the already created firebase session
      yield call(AuthService.logout);
      throw Error(API_ERROR_FEEDBACK.domainError);
    }
    const response = yield AuthService.userDepartments();
    yield put(authActions.loadApplication());
    const data: UserData = {
      department: response.data,
      id: action.payload.email,
      isAdmin: response.data.length > 1 ? true : false,
      loading: false,
      isLoggedIn: true,
      photoUrl: action.payload.photoUrl
    };
    yield put(
      authActions.userDetails.done({
        params: action.payload,
        result: data
      })
    );
  } catch (error) {
    yield put(authActions.userDetails.failed(error.message));
    yield put(errorActions.setAppError(error.message));
  }
}

function* AuthWatcher(): IterableIterator<any> {
  yield takeEvery(authActions.loginUser.started, LoginWorker);
  yield takeEvery(authActions.logoutUser.started, LogoutWorker);
  yield takeEvery(authActions.userDetails.started, UserDetailsWatcher);
}

export default AuthWatcher;
