import { css } from "@emotion/core";
import { breakpoints } from "../../theme";

export const CardStyles = css({
  ".ant-card-body": {
    display: "flex",
    ".ant-card-grid": {
      boxShadow: "unset",
      width: "100%"
    },
    ".image-container img": {
      maxWidth: "100%"
    }
  },
  [`@media screen and (max-width: ${breakpoints.md})`]: {
    ".ant-card-body": {
      flexDirection: "column"
    }
  },
  [`@media screen and (min-width: ${breakpoints.lg})`]: {
    ".ant-card-body": {
      ".text-container": {
        flexGrow: 0.7
      },
      ".image-container": {
        flexGrow: 0.3
      }
    }
  }
});
