import React, { Suspense, useEffect } from "react";
import { Global } from "@emotion/core";
import { message } from "antd";
import { throttle } from "lodash";

import { logEvent } from "./helper/firebaseUtils";
import { firestoreDatabase } from "./firebase";

import useAuth from "./hooks/authentication/useAuth";
import useError from "./hooks/error/useError";
import useRedirect from "./helperHooks/useRedirect";
import { store } from "./store/store";
import RoutesContainer from "./RoutesContainer";
import { authActions } from "./store/user/actions";
import { theme } from "./theme";
import "./App.css";

const App: React.FC = () => {
  const redirect = useRedirect();
  const { getUserDetails, updateFirebaseStatus } = useAuth();

  const { error, isDisplayed, updateErrorStatus } = useError();

  //display the cloud functions error messages
  useEffect(() => {
    if (error && !isDisplayed) {
      updateErrorStatus();
      throttle(message.error(error, 4), 500);
    }
  }, [error, isDisplayed]);

  //monitor the firestore for session
  useEffect(() => {
    firestoreDatabase.auth().onAuthStateChanged(async (user) => {
      if (user?.email) {
        getUserDetails({
          email: user.email,
          photoUrl: user.photoURL || undefined
        });
        logEvent(`${user.email} is logged in`);
      } else {
        logEvent(`user has logged out`);
        store.dispatch(authActions.flushData());
        updateFirebaseStatus();
        redirect((routes) => routes.home.url);
      }
    });
  }, []);

  return (
    <div className="App">
      <Global styles={theme} />
      <Suspense fallback={null}>
        <RoutesContainer />
      </Suspense>
    </div>
  );
};

export default App;
