import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useSelector, useDispatch } from "react-redux";
//services
import { JiraData } from "../../services/jiraService/jiraService.type";
//store
import { jiraActions } from "../../store/jira/actions";
import { ApplicationState } from "../../store/rootReducers";

interface Selectors {
  departmentState: JiraData;
}
interface ActionDispatchers {
  getDepartmentData: (departmentPath: string) => void;
}

/**
 * @description accesses Redux, returns required state,actions
 *
 * @returns {(Selectors & ActionDispatchers)}
 */
const useDepartment = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);

  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  departmentState: state.jiraState.departmentState
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  getDepartmentData: (departmentPath: string) => {
    dispatch(jiraActions.getDepartmentData.started("/" + departmentPath));
  }
});

export default useDepartment;
